<template>
  <div>
    <Loading v-show="show" />

    <div class="breadcrumb-banner-area">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="breadcrumb-text">
              <h1 class="text-center" id="postTitleId"></h1>
              <p id="postSubTitleId" class="text-center"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="about-page-area section-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <div class="about-text-container">
              <p id="postDetailsId"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "./Loading";

import axios from "axios";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      show: true,
    };
  },
  methods: {
    getApis(lang) {
      var bodyFormData = new FormData();
      var self = this;
      bodyFormData.append("check", "getPostById");
      bodyFormData.append("Lang", lang);
      bodyFormData.append("PostId", this.$route.params.id);
      bodyFormData.append("FbrnId", "");
      bodyFormData.append("Type", this.$route.params.type);
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "10");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData,
      })
        .then(function (response) {
          var arr = response.data;
          if (arr.length != 0) {
            document.getElementById("postTitleId").innerText =
              response.data[0]["postTitle"];
            document.getElementById("postSubTitleId").innerText =
              response.data[0]["postSubTitle"];
            document.getElementById("postDetailsId").innerHTML =
              response.data[0]["postDetails"];
            // document
            //   .getElementById("postImageId")
            //   .setAttribute(
            //     "src",
            //     "https://api2.yuniv.net:444/Images/post/" + response.data[0]["postImage"]
            //   );
          }
          document.getElementById("VueMainJsNewTouch")?.remove();
          let recaptchaScript = document.createElement("script");
          recaptchaScript.setAttribute("src", "/js/main.js");
          recaptchaScript.id = "VueMainJsNewTouch";
          document.head.appendChild(recaptchaScript);

          self.show = false;
        })
        .catch(function (response) {
          // eslint-disable-next-line no-console
          console.log("error", response);
        });
      setTimeout(() => {
        self.show = "false";
        document.querySelectorAll("div.loader").forEach(function (element) {
          element.setAttribute("hidden", true);
        });
      }, 300);
    },
  },
  mounted() {
    window.scroll(0, 0);
    var self = this;
    self.show=true;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      })
        .then(function (response) {
          self.lang = response.data[0]["LangId"];
          localStorage.setItem("lang", response.data[0]["LangId"]);
          if (response.data[0]["LangId"] == "Ar") {
            document.getElementById("langfilewebsites")?.remove();
          } else {
            self.translate = self.EnTranslat;
            let link1 = document.createElement("link");
            link1.setAttribute("rel", "stylesheet");
            link1.id = "langfilewebsites";
            link1.setAttribute("href", "../../../style-ltr.css");
            document.head.appendChild(link1);
            // self.getApis();
          }
          self.getApis(response.data[0]["LangId"]);
        })
        .catch(function (response) {
          // eslint-disable-next-line no-console
          console.log("error", response);
        });
    } else {
      if (localStorage.getItem("lang") == "Ar") {
        document.getElementById("langfilewebsites")?.remove();
        self.getApis("Ar");
      } else {
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "../../../style-ltr.css");
        document.head.appendChild(link1);
        self.getApis("En");
      }
    }
    setTimeout(() => {
        self.show = "false";
        document.querySelectorAll("div.loader").forEach(function (element) {
          element.setAttribute("hidden", true);
        });
      }, 300);
  },
};
</script>

<style scoped>
.about-text-container p {
  text-align: justify;
}
.breadcrumb-text p {
  text-align: justify;
}
.breadcrumb-text h1:after {
  background: #576573;
  bottom: 0;
  content: "";
  height: 29px;
  right: 0;
  margin: auto;
  position: absolute;
  left: 0;
  width: 2px;
}
.section-padding {
  padding: 50px 0px;
}
</style>
